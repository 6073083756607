import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

const Demo = () => {
  return (
    <DemoImage
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M92.2601 435.51C181.09 483 325.13 483 414 435.51C502.87 388.02 502.84 311.13 414 263.68C325.16 216.23 181.13 216.23 92.3001 263.68C3.47006 311.13 3.42007 388.06 92.2601 435.51Z"
        fill="#F5F5F5"
      />
      <motion.path
        d="M86.9301 350.09L84.3701 348.61C83.2745 347.896 82.363 346.933 81.7096 345.8C81.0563 344.667 80.6796 343.396 80.6101 342.09V117.68C80.6796 116.374 81.0563 115.103 81.7096 113.97C82.363 112.837 83.2745 111.874 84.3701 111.16L187.87 51.3999C189.037 50.8086 190.327 50.5005 191.635 50.5005C192.943 50.5005 194.233 50.8086 195.4 51.3999L198 52.8799C199.096 53.5941 200.007 54.5569 200.661 55.6898C201.314 56.8228 201.691 58.0939 201.76 59.3999V283.81C201.691 285.116 201.314 286.387 200.661 287.52C200.007 288.653 199.096 289.616 198 290.33L94.4701 350.09C93.3014 350.681 92.0099 350.989 90.7001 350.989C89.3903 350.989 88.0988 350.681 86.9301 350.09Z"
        fill="#37474F"
      />
      <motion.path
        d="M95.7501 122.07L81.7501 113.97C81.0736 115.093 80.695 116.37 80.6501 117.68V342.09C80.7196 343.396 81.0963 344.667 81.7497 345.8C82.403 346.933 83.3146 347.896 84.4101 348.61L86.9701 350.09C88.1389 350.681 89.4303 350.99 90.7401 350.99C92.05 350.99 93.3414 350.681 94.5101 350.09L95.7901 349.35L95.7501 122.07Z"
        fill="#263238"
      />
      <motion.path
        d="M90.7002 123.51V347.92C90.7002 350.32 92.3902 351.29 94.4702 350.09L198 290.33C199.096 289.616 200.007 288.653 200.661 287.52C201.314 286.387 201.691 285.116 201.76 283.81V59.3999C201.76 56.9999 200.08 56.0299 198 57.2299L94.4702 117C93.3768 117.715 92.4663 118.676 91.8116 119.807C91.1569 120.937 90.7762 122.206 90.7002 123.51Z"
        fill="#455A64"
      />
      <motion.path
        d="M99 137.69L193.11 83.3599C194.89 82.3599 196.34 83.1699 196.34 85.2299V264.23C196.279 265.351 195.954 266.442 195.393 267.415C194.832 268.388 194.05 269.215 193.11 269.83L99 324.18C97.21 325.18 95.77 324.37 95.77 322.31V143.31C95.8245 142.184 96.1457 141.088 96.7073 140.11C97.2689 139.133 98.0547 138.304 99 137.69Z"
        fill="#FAFAFA"
      />
      <motion.path
        d="M146.22 92.93C147.61 92.13 148.74 92.78 148.74 94.39C148.694 95.2653 148.441 96.1173 148.003 96.8766C147.565 97.636 146.954 98.2813 146.22 98.76C144.82 99.57 143.69 98.91 143.69 97.31C143.738 96.4324 143.992 95.5785 144.431 94.8175C144.871 94.0565 145.484 93.4098 146.22 92.93Z"
        fill="#263238"
      />
      <motion.path
        d="M138.93 105.54C144.06 102.71 153.2 97.2598 158.26 94.2898C159.12 93.7798 159.81 94.1298 159.81 95.0698C159.771 95.5934 159.609 96.1004 159.338 96.5502C159.067 97 158.694 97.38 158.25 97.6598L138.89 108.84C138.03 109.34 137.33 108.98 137.33 108.05C137.374 107.533 137.543 107.035 137.822 106.598C138.1 106.161 138.481 105.798 138.93 105.54Z"
        fill="#263238"
      />
      <motion.path
        d="M134 108.37C134.78 107.91 135.41 108.28 135.41 109.18C135.385 109.672 135.245 110.151 135 110.579C134.755 111.006 134.412 111.37 134 111.64C133.22 112.09 132.58 111.72 132.58 110.82C132.608 110.329 132.752 109.851 132.998 109.426C133.245 109 133.588 108.639 134 108.37V108.37Z"
        fill="#263238"
      />
      <motion.path
        d="M146.22 302.73C149 301.12 151.22 302.42 151.22 305.64C151.131 307.387 150.632 309.088 149.763 310.606C148.894 312.125 147.681 313.417 146.22 314.38C143.43 315.99 141.17 314.69 141.17 311.47C141.265 309.719 141.772 308.014 142.65 306.496C143.527 304.977 144.75 303.687 146.22 302.73Z"
        fill="#263238"
      />
      <motion.path
        d="M251.5 338.79C250.885 338.371 250.378 337.813 250.018 337.162C249.659 336.51 249.457 335.784 249.43 335.04V215.66C249.471 214.893 249.692 214.146 250.076 213.48C250.46 212.815 250.996 212.249 251.64 211.83L404.79 123.41C405.429 123.007 406.166 122.786 406.922 122.772C407.677 122.757 408.423 122.949 409.077 123.326C409.732 123.704 410.271 124.253 410.636 124.915C411.001 125.576 411.179 126.325 411.15 127.08V246.46C411.109 247.227 410.888 247.974 410.504 248.64C410.12 249.305 409.584 249.871 408.94 250.29L255.79 338.72C255.13 339.066 254.398 339.253 253.653 339.265C252.908 339.277 252.171 339.114 251.5 338.79Z"
        fill="#455A64"
      />
      <motion.path
        d="M411.13 126.71C410.97 125.57 410.05 125.15 408.94 125.8L255.79 214.22C255.136 214.625 254.598 215.194 254.23 215.87L250.08 213.48C250.448 212.804 250.986 212.235 251.64 211.83L404.8 123.4C405.411 123.014 406.114 122.795 406.836 122.766C407.559 122.737 408.277 122.898 408.918 123.233C409.559 123.568 410.1 124.066 410.488 124.676C410.877 125.286 411.098 125.987 411.13 126.71V126.71Z"
        fill="#37474F"
      />
      <motion.path
        d="M255.27 338.94C255.221 338.97 255.167 338.991 255.11 339C255.012 339.048 254.908 339.082 254.8 339.1H254.63C254.526 339.13 254.418 339.151 254.31 339.16H254.05C253.668 339.21 253.282 339.21 252.9 339.16C252.413 339.11 251.94 338.963 251.51 338.73C250.892 338.313 250.382 337.756 250.021 337.104C249.659 336.453 249.457 335.725 249.43 334.98V215.65C249.458 214.883 249.682 214.136 250.08 213.48L254.23 215.87C253.831 216.53 253.608 217.28 253.58 218.05V337.44C253.546 337.729 253.596 338.023 253.726 338.284C253.856 338.545 254.059 338.762 254.31 338.91C254.42 338.964 254.538 338.998 254.66 339.01C254.796 339.029 254.934 339.029 255.07 339.01L255.27 338.94Z"
        fill="#263238"
      />
      <motion.path
        d="M266 249.17V268C266 269.41 267 270 268.21 269.28L292.81 255.07C293.453 254.649 293.988 254.084 294.372 253.418C294.755 252.753 294.978 252.007 295.02 251.24V232.42C295.02 231.01 294.02 230.42 292.81 231.14L268.21 245.35C267.566 245.767 267.031 246.331 266.646 246.995C266.262 247.658 266.041 248.404 266 249.17V249.17Z"
        className="rainbow"
      />
      <motion.path
        d="M298.926 234.165L261.617 255.723L262.117 256.589L299.427 235.03L298.926 234.165Z"
        fill="white"
      />
      <motion.path
        d="M274.81 248.96H273.81V258.56H274.81V248.96Z"
        fill="white"
      />
      <motion.path
        d="M287.25 241.82H286.25V251.4H287.25V241.82Z"
        fill="white"
      />
      <motion.path
        d="M298.929 243.784L261.62 265.342L262.12 266.208L299.43 244.65L298.929 243.784Z"
        fill="white"
      />
      <motion.path
        d="M267 233.93C266.89 233.87 266.84 233.71 266.84 233.45V223C266.833 222.766 266.889 222.535 267 222.33C267.124 222.121 267.304 221.951 267.52 221.84L271.98 219.26C273.09 218.62 273.93 218.43 274.49 218.7C275.05 218.97 275.33 219.63 275.33 220.7C275.324 221.274 275.205 221.842 274.98 222.37C274.766 222.914 274.45 223.413 274.05 223.84C275.05 223.64 275.61 224.22 275.61 225.59C275.601 226.276 275.465 226.954 275.21 227.59C274.926 228.324 274.513 229.001 273.99 229.59C273.416 230.238 272.74 230.788 271.99 231.22L267.53 233.79C267.3 234 267.12 234 267 233.93ZM271.55 224.37C271.939 224.157 272.266 223.847 272.5 223.47C272.721 223.106 272.836 222.686 272.83 222.26C272.83 221.78 272.73 221.5 272.52 221.41C272.31 221.32 271.99 221.41 271.52 221.66L269.32 222.94V225.64L271.55 224.37ZM271.8 229.22C272.201 228.995 272.536 228.667 272.768 228.27C273 227.872 273.121 227.42 273.12 226.96C273.12 226.46 272.99 226.15 272.74 226.05C272.556 226.006 272.364 226.004 272.179 226.044C271.994 226.084 271.82 226.164 271.67 226.28L269.35 227.63V230.63L271.8 229.22Z"
        fill="white"
      />
      <motion.path
        d="M275.6 228.86C275.588 228.757 275.588 228.653 275.6 228.55L279.54 215.44C279.592 215.247 279.669 215.062 279.77 214.89C279.866 214.749 279.997 214.636 280.15 214.56L281.26 213.92C281.42 213.83 281.54 213.79 281.62 213.82C281.728 213.883 281.809 213.982 281.85 214.1L285.79 222.67C285.804 222.753 285.804 222.837 285.79 222.92C285.792 223.014 285.768 223.107 285.72 223.188C285.672 223.269 285.603 223.336 285.52 223.38L283.68 224.44C283.591 224.508 283.482 224.543 283.37 224.54C283.3 224.54 283.25 224.46 283.19 224.35L282.5 222.68L278.8 224.82L278.11 227.28C278.077 227.422 278.019 227.557 277.94 227.68C277.854 227.783 277.749 227.868 277.63 227.93L275.88 229C275.69 229.1 275.6 229.06 275.6 228.86ZM282 221L280.73 217.93L279.46 222.46L282 221Z"
        fill="white"
      />
      <motion.path
        d="M287.31 222.21C287.2 222.15 287.15 221.99 287.15 221.74V211.3C287.142 211.07 287.198 210.841 287.31 210.64C287.439 210.426 287.626 210.252 287.85 210.14L288.79 209.6C288.906 209.519 289.04 209.468 289.18 209.45C289.288 209.465 289.387 209.518 289.46 209.6L293.72 214.08V207.53C293.712 207.293 293.767 207.059 293.88 206.85C294.007 206.644 294.186 206.475 294.4 206.36L295.54 205.7C295.8 205.55 295.97 205.51 296.08 205.58C296.19 205.65 296.23 205.81 296.23 206.08V216.49C296.237 216.719 296.185 216.946 296.08 217.15C295.947 217.359 295.761 217.528 295.54 217.64L294.54 218.2C294.427 218.277 294.296 218.322 294.16 218.33C294.053 218.318 293.954 218.268 293.88 218.19L289.61 213.73V220.29C289.616 220.516 289.564 220.74 289.46 220.94C289.328 221.152 289.142 221.325 288.92 221.44L287.78 222.09C287.59 222.24 287.41 222.27 287.31 222.21Z"
        fill="white"
      />
      <motion.path
        d="M306.34 211.11C306.342 211.204 306.317 211.297 306.27 211.378C306.222 211.459 306.153 211.526 306.07 211.57L304.17 212.66C304.083 212.714 303.989 212.754 303.89 212.78C303.822 212.789 303.754 212.771 303.7 212.73L300.34 209.57V214.19C300.346 214.419 300.294 214.646 300.19 214.85C300.055 215.057 299.869 215.225 299.65 215.34L298.51 216C298.27 216.14 298.1 216.18 297.99 216.12C297.88 216.06 297.83 215.89 297.83 215.64V205.18C297.822 204.946 297.877 204.715 297.99 204.51C298.116 204.3 298.296 204.128 298.51 204.01L299.65 203.35C299.91 203.21 300.08 203.17 300.19 203.23C300.3 203.29 300.34 203.46 300.34 203.73V208.26V208.17L303.59 201.28C303.636 201.173 303.7 201.075 303.78 200.99C303.875 200.903 303.979 200.826 304.09 200.76L305.96 199.68C306.15 199.58 306.24 199.63 306.24 199.83C306.233 199.945 306.203 200.057 306.15 200.16L302.54 207.6L306.25 210.88C306.282 210.908 306.307 210.944 306.323 210.984C306.338 211.024 306.344 211.067 306.34 211.11V211.11Z"
        fill="white"
      />
      <motion.path
        d="M314.25 205.5C313.25 204.99 312.79 203.73 312.79 201.71C312.766 199.796 313.256 197.91 314.21 196.25C315.199 194.604 316.603 193.246 318.28 192.31C318.908 191.929 319.58 191.624 320.28 191.4C320.771 191.23 321.295 191.178 321.81 191.25C321.832 191.253 321.854 191.259 321.874 191.27C321.893 191.281 321.911 191.296 321.925 191.314C321.939 191.331 321.949 191.352 321.955 191.373C321.961 191.395 321.963 191.418 321.96 191.44C321.951 191.561 321.921 191.68 321.87 191.79L321.33 193.17C321.293 193.292 321.22 193.4 321.12 193.48C321.04 193.522 320.95 193.539 320.86 193.53C320.045 193.528 319.248 193.768 318.57 194.22C317.549 194.799 316.705 195.647 316.13 196.67C315.54 197.777 315.243 199.016 315.27 200.27C315.27 201.65 315.57 202.52 316.17 202.86C316.58 203.016 317.023 203.061 317.457 202.993C317.89 202.925 318.298 202.744 318.64 202.47C319.56 201.958 320.377 201.28 321.05 200.47C321.123 200.371 321.211 200.283 321.31 200.21C321.38 200.21 321.45 200.21 321.52 200.28L322.11 201.12C322.146 201.17 322.167 201.229 322.17 201.29C322.142 201.427 322.08 201.554 321.99 201.66C321.003 202.945 319.751 204.002 318.32 204.76C316.58 205.76 315.21 206 314.25 205.5Z"
        fill="white"
      />
      <motion.path
        d="M322.74 201.64C322.733 201.54 322.733 201.44 322.74 201.34L326.74 188.22C326.783 188.024 326.861 187.838 326.97 187.67C327.069 187.538 327.196 187.429 327.34 187.35L328.45 186.7C328.557 186.622 328.69 186.59 328.82 186.61C328.93 186.67 329.013 186.77 329.05 186.89L332.99 195.45C333.004 195.536 333.004 195.624 332.99 195.71C332.994 195.803 332.97 195.895 332.922 195.975C332.874 196.055 332.804 196.119 332.72 196.16L330.88 197.23C330.789 197.293 330.681 197.328 330.57 197.33C330.528 197.313 330.49 197.288 330.459 197.255C330.428 197.222 330.404 197.183 330.39 197.14L329.69 195.47L326 197.6L325.32 200.07C325.283 200.208 325.226 200.339 325.15 200.46C325.063 200.568 324.954 200.657 324.83 200.72L323 201.78C322.83 201.89 322.74 201.84 322.74 201.64ZM329.14 193.78L327.88 190.72L326.6 195.25L329.14 193.78Z"
        fill="white"
      />
      <motion.path
        d="M334.44 195C334.34 194.94 334.28 194.78 334.28 194.52V184.1C334.278 183.867 334.333 183.637 334.44 183.43C334.571 183.223 334.754 183.055 334.97 182.94L338.82 180.72C341.386 179.227 342.673 179.78 342.68 182.38C342.686 183.196 342.53 184.005 342.22 184.76C341.905 185.541 341.447 186.257 340.87 186.87L342.47 189.99C342.486 190.062 342.486 190.138 342.47 190.21C342.47 190.315 342.44 190.418 342.383 190.507C342.326 190.596 342.245 190.666 342.15 190.71L340.47 191.71C340.375 191.779 340.265 191.823 340.15 191.84C340.07 191.84 340.01 191.78 339.96 191.67L338.56 188.67L336.75 189.67V193.11C336.766 193.348 336.71 193.585 336.588 193.791C336.467 193.996 336.286 194.16 336.07 194.26L334.93 194.92C334.73 195 334.55 195.06 334.44 195ZM338.44 186.58C338.962 186.301 339.408 185.9 339.74 185.41C340.038 184.938 340.191 184.388 340.18 183.83C340.18 183.22 340.04 182.83 339.78 182.72C339.52 182.61 339.12 182.72 338.59 182.98L336.79 184V187.51L338.44 186.58Z"
        fill="white"
      />
      <motion.path
        d="M344.09 189.43C343.98 189.37 343.93 189.21 343.93 188.95V178.54C343.922 178.306 343.977 178.075 344.09 177.87C344.213 177.658 344.394 177.485 344.61 177.37L347.81 175.53C349.543 174.523 350.86 174.24 351.76 174.68C352.66 175.12 353.11 176.37 353.11 178.43C353.16 180.292 352.689 182.131 351.75 183.74C350.772 185.296 349.417 186.579 347.81 187.47L344.61 189.31C344.37 189.45 344.19 189.49 344.09 189.43ZM347.93 185.27C348.771 184.804 349.464 184.111 349.93 183.27C350.424 182.218 350.654 181.061 350.6 179.9C350.6 178.5 350.38 177.64 349.93 177.32C349.48 177 348.81 177.11 347.93 177.63L346.44 178.49V186.16L347.93 185.27Z"
        fill="white"
      />
      <motion.path
        d="M266 285.09V289.56C266 290.97 267 291.56 268.21 290.84L292.81 276.63C293.449 276.207 293.98 275.641 294.36 274.975C294.741 274.31 294.96 273.565 295 272.8V268.33C295 266.92 294 266.33 292.79 267.05L268.19 281.26C267.55 281.682 267.018 282.248 266.637 282.913C266.257 283.579 266.039 284.324 266 285.09Z"
        fill="#EBEBEB"
      />
      <motion.path
        d="M300.57 265.14V269.61C300.57 271.02 301.57 271.61 302.78 270.88L327.39 256.68C328.033 256.26 328.568 255.694 328.951 255.029C329.335 254.363 329.557 253.617 329.6 252.85V248.38C329.6 246.97 328.6 246.38 327.39 247.1L302.78 261.31C302.136 261.729 301.6 262.295 301.216 262.96C300.832 263.626 300.611 264.373 300.57 265.14V265.14Z"
        fill="#EBEBEB"
      />
      <motion.path
        d="M335.13 245.18V249.65C335.13 251.06 336.13 251.65 337.34 250.93L361.94 236.72C362.584 236.303 363.119 235.739 363.503 235.075C363.888 234.411 364.109 233.666 364.15 232.9V228.42C364.15 227.01 363.15 226.42 361.94 227.15L337.34 241.35C336.697 241.77 336.162 242.336 335.778 243.002C335.394 243.667 335.172 244.413 335.13 245.18V245.18Z"
        fill="#EBEBEB"
      />
      <motion.path
        d="M369.68 225.23V229.7C369.68 231.11 370.68 231.7 371.89 230.98L396.5 216.77C397.144 216.351 397.68 215.785 398.064 215.12C398.448 214.454 398.669 213.707 398.71 212.94V208.47C398.71 207.06 397.71 206.47 396.5 207.19L371.89 221.4C371.247 221.82 370.712 222.386 370.328 223.051C369.944 223.717 369.722 224.463 369.68 225.23V225.23Z"
        fill="#EBEBEB"
      />
      <motion.path
        d="M384.94 230.63L379.3 233.89C378.915 234.141 378.595 234.479 378.366 234.877C378.137 235.275 378.004 235.721 377.98 236.18V237.91C377.98 238.75 378.57 239.1 379.3 238.67L384.94 235.42C385.328 235.169 385.651 234.83 385.882 234.43C386.113 234.03 386.246 233.581 386.27 233.12V231.39C386.27 230.55 385.68 230.21 384.94 230.63Z"
        className="rainbow"
      />
      <motion.path
        d="M397.38 223.45L391.74 226.7C391.355 226.953 391.035 227.293 390.806 227.693C390.577 228.092 390.444 228.54 390.42 229V230.73C390.42 231.57 391.01 231.91 391.74 231.49L397.38 228.23C397.767 227.981 398.089 227.643 398.32 227.245C398.552 226.847 398.685 226.4 398.71 225.94V224.21C398.71 223.37 398.12 223 397.38 223.45Z"
        className="rainbow"
      />
      <motion.path
        d="M326.17 276.53L396.39 236C397.61 235.29 398.6 235.87 398.6 237.28V239.35C398.559 240.117 398.338 240.864 397.954 241.53C397.57 242.195 397.034 242.761 396.39 243.18L326.17 283.72C324.95 284.43 323.96 283.86 323.96 282.45V280.37C324.001 279.601 324.222 278.853 324.606 278.186C324.99 277.519 325.526 276.951 326.17 276.53V276.53Z"
        className="rainbow"
      />
      <rect x="295" y="229" width="7" height="24" fill="#455A64" />
      <motion.path
        d="M177.31 110V212.82C177.351 213.571 177.568 214.301 177.943 214.952C178.319 215.604 178.842 216.158 179.47 216.57L183.27 218.76C183.941 219.1 184.683 219.277 185.435 219.277C186.187 219.277 186.929 219.1 187.6 218.76L252.32 181.4C252.95 180.989 253.475 180.435 253.852 179.784C254.229 179.132 254.448 178.401 254.49 177.65V74.85C254.449 74.0982 254.231 73.3667 253.854 72.715C253.477 72.0634 252.952 71.51 252.32 71.1L248.53 68.91C247.859 68.57 247.117 68.3928 246.365 68.3928C245.613 68.3928 244.871 68.57 244.2 68.91L179.47 106.28C178.845 106.689 178.325 107.239 177.95 107.885C177.575 108.53 177.356 109.255 177.31 110V110Z"
        fill="#EBEBEB"
      />
      <motion.path
        d="M254.47 74.5C254.32 73.38 253.41 72.97 252.32 73.6L187.6 111C186.957 111.401 186.427 111.958 186.06 112.62L177.94 107.93C178.309 107.273 178.835 106.716 179.47 106.31L244.2 68.91C244.871 68.57 245.613 68.3928 246.365 68.3928C247.117 68.3928 247.859 68.57 248.53 68.91L252.32 71.1C252.902 71.4789 253.395 71.9788 253.766 72.5655C254.137 73.1523 254.377 73.8121 254.47 74.5V74.5Z"
        fill="#F5F5F5"
      />
      <motion.path
        d="M187.22 218.93C187.177 218.938 187.133 218.938 187.09 218.93C187.007 218.97 186.92 219.001 186.83 219.02V219.02L186.39 219.13C185.517 219.293 184.616 219.221 183.78 218.92C183.615 218.859 183.454 218.785 183.3 218.7L179.5 216.51C178.872 216.098 178.349 215.544 177.973 214.892C177.598 214.241 177.381 213.511 177.34 212.76V110C177.366 109.248 177.583 108.515 177.97 107.87L186.09 112.56C185.703 113.205 185.486 113.938 185.46 114.69V217.51C185.438 217.916 185.554 218.318 185.79 218.65C185.928 218.816 186.11 218.939 186.316 219.005C186.522 219.07 186.742 219.076 186.95 219.02L187.22 218.93Z"
        fill="#E0E0E0"
      />
      <motion.path
        d="M229.72 150.82C232.55 152.71 236.18 152.82 239.86 152.13"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M220.45 131C223.45 130.13 225.26 131.61 225.58 135.85C225.97 141.07 225.85 145.98 228.53 148.99"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M215.52 159C213.26 153.48 212.1 136.44 218.52 132"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M234.89 169.5C227.52 170.37 220.57 168.16 216.64 161.26"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M236.35 165.47C224.49 166.76 216.04 160.73 217.43 140.53C217.57 138.63 218.62 136.45 220.03 135.63H220.08C221.52 134.84 222.98 135.77 222.95 137.69C222.74 152.14 227.42 158.85 239.27 156.75"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M232.13 174.32C217.3 175.32 209.36 165.47 210.53 144.7C210.92 137.84 213.53 130.24 218.67 127.27C218.74 127.221 218.813 127.177 218.89 127.14C224.11 124.27 228.18 127.28 228.72 134.32C229.24 141.08 229.43 149.06 240.6 147.17"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M222 178.08C224.248 178.884 226.613 179.313 229 179.35"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M210.53 169C212.444 172.758 215.635 175.712 219.53 177.33"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M211.42 130.73C205.02 142.54 206.65 158.38 209.3 166.02"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M232 133.52C231.4 127.11 229.77 122.73 225.12 121.7C221.41 120.89 216.66 123.24 212.89 128.42"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M241.07 142.73C237.25 144.07 233.19 143.24 232.48 137.44"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M241.34 138.32C237.34 138.43 235.49 136.65 234.52 129.48C233.03 118.48 227.34 113.77 218.52 118.87C209.26 124.21 203.7 137.44 203.28 148.38C202.47 168.97 208.93 177.16 219.08 181.31C221.346 182.214 223.719 182.823 226.14 183.12"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M236.32 123.28C237.11 127.61 237.53 133.65 241.45 133.52"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M227.66 112.28C231.45 112.99 234.29 116.14 235.73 120.78"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M212.91 118.7C216.91 114.56 221.62 112.31 225.58 112.14"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M201.4 138.43C203.099 131.943 206.217 125.915 210.53 120.78"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M199.92 159C199.225 153.127 199.424 147.183 200.51 141.37"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M205 175.9C202.675 171.74 201.098 167.205 200.34 162.5"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M220.23 187.53C215.53 185.61 210.29 182.81 206.96 178.33"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M215 190.53C200.91 184.77 196 169.58 196 152.68C196 135.78 204 117.58 218.49 109.63C228.32 104.25 241.43 110.08 241.43 126.98"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M233 161.34C234.769 161.428 236.543 161.358 238.3 161.13"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M223.32 156C224.034 157.342 225.045 158.503 226.275 159.395C227.506 160.287 228.923 160.888 230.42 161.15"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M220.33 138.9C219.85 144.7 220.58 150.03 222.23 153.82"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M224.69 102.3C228.699 101.485 232.867 102.256 236.32 104.45"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M207 113C210.971 108.658 215.902 105.306 221.4 103.21"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M202.07 187.84C203.522 189.65 205.347 191.127 207.42 192.17"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M195.48 176.7C196.599 179.802 198.217 182.7 200.27 185.28"
        stroke="#455A64"
        stroke-width="1.25"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <motion.path
        d="M38.4399 235.44C38.4763 234.774 38.668 234.126 38.9996 233.548C39.3313 232.97 39.7937 232.477 40.3499 232.11L138.5 175.45C139.095 175.149 139.753 174.993 140.42 174.993C141.087 174.993 141.745 175.149 142.34 175.45L144.5 176.7C145.058 177.063 145.523 177.554 145.857 178.13C146.19 178.707 146.383 179.355 146.42 180.02V255.25C146.383 255.917 146.19 256.565 145.857 257.144C145.523 257.722 145.059 258.214 144.5 258.58L46.3499 315.24C45.7563 315.54 45.1003 315.697 44.4349 315.697C43.7696 315.697 43.1136 315.54 42.5199 315.24L40.3599 314C39.8015 313.636 39.3367 313.146 39.0031 312.569C38.6695 311.993 38.4766 311.345 38.4399 310.68V235.44Z"
        className="rainbow"
      />
      <motion.path
        opacity="0.4"
        d="M39 233.55L44.44 236.69L145.86 178.13C145.533 177.547 145.066 177.055 144.5 176.7L142.33 175.45C141.736 175.149 141.08 174.993 140.415 174.993C139.75 174.993 139.094 175.149 138.5 175.45L40.36 232.11C39.7891 232.463 39.3204 232.96 39 233.55V233.55Z"
        fill="white"
      />
      <motion.path
        opacity="0.1"
        d="M38.4299 235.44V310.67C38.4669 311.338 38.661 311.987 38.9964 312.566C39.3317 313.144 39.7989 313.636 40.3599 314L42.5199 315.25C42.6865 315.344 42.8604 315.424 43.0399 315.49C43.7858 315.733 44.5829 315.775 45.3499 315.61C45.4759 315.587 45.5997 315.554 45.7199 315.51C45.8003 315.49 45.8777 315.46 45.9499 315.42C45.8047 315.483 45.6481 315.515 45.4899 315.515C45.3317 315.515 45.1752 315.483 45.0299 315.42C44.8249 315.282 44.6614 315.091 44.5574 314.867C44.4534 314.643 44.4127 314.395 44.4399 314.15V238.9C44.4197 238.558 44.5047 238.219 44.6835 237.928C44.8624 237.636 45.1264 237.406 45.4399 237.27L44.4399 236.69L38.9999 233.55C38.651 234.12 38.4547 234.771 38.4299 235.44V235.44Z"
        fill="black"
      />
      <motion.path
        opacity="0.2"
        d="M44.4398 236.69L89.8898 253.69C91.4624 254.277 93.1883 254.311 94.7826 253.786C96.377 253.261 97.7447 252.207 98.6598 250.8L106 239.6L146.26 177.92L107.42 240L146.42 255.27C146.382 255.947 146.176 256.603 145.82 257.18L107.31 240.18L98.8198 253.75C97.8789 255.256 96.4267 256.372 94.7295 256.893C93.0324 257.415 91.204 257.307 89.5798 256.59L83.5798 253.92L44.9998 315.4C44.7947 315.262 44.6313 315.071 44.5273 314.847C44.4233 314.623 44.3826 314.375 44.4098 314.13V312.88L83.3198 253.88L44.4398 236.69Z"
        fill="black"
      />
      <motion.path
        d="M391.22 27.9999C391.246 27.7402 391.205 27.4783 391.102 27.2387C390.999 26.999 390.836 26.7896 390.63 26.6299V26.6299H390.58L386.58 24.2999C386.336 24.1865 386.067 24.1405 385.799 24.1669C385.532 24.1933 385.277 24.291 385.06 24.4499L323 60.1199L321.33 54.5499C321.197 54.0483 320.874 53.618 320.43 53.3499L316.39 50.9999C316.085 50.8225 315.736 50.7332 315.383 50.7421C315.03 50.7509 314.686 50.8574 314.39 51.0499L287.77 66.4299C287.141 66.8422 286.618 67.3963 286.243 68.0476C285.868 68.699 285.651 69.4293 285.61 70.1799V159C285.587 159.259 285.629 159.52 285.732 159.759C285.835 159.999 285.996 160.208 286.2 160.37V160.37H286.25L290.25 162.7H290.3C290.542 162.795 290.804 162.828 291.063 162.797C291.321 162.765 291.567 162.67 291.78 162.52L389 106.39C389.628 105.978 390.151 105.423 390.526 104.772C390.902 104.121 391.119 103.39 391.16 102.64L391.22 27.9999Z"
        className="rainbow"
      />
      <motion.path
        opacity="0.4"
        d="M320.4 53.3199C320.097 53.1631 319.759 53.0888 319.418 53.1046C319.077 53.1204 318.747 53.2257 318.46 53.4099L291.83 68.7799C291.195 69.1863 290.67 69.7426 290.3 70.3999L286.24 68.0499C286.601 67.3862 287.128 66.8279 287.77 66.4299L314.4 51.0599C314.697 50.8696 315.04 50.7643 315.393 50.7555C315.746 50.7467 316.094 50.8346 316.4 51.0099V51.0099L320.4 53.3199Z"
        fill="white"
      />
      <motion.path
        opacity="0.4"
        d="M390.47 26.5099C390.233 26.4335 389.982 26.4127 389.736 26.4491C389.489 26.4855 389.255 26.5782 389.05 26.7199L326.39 62.8699C326.135 63.0395 325.843 63.1448 325.539 63.1767C325.235 63.2086 324.928 63.1662 324.643 63.0531C324.359 62.94 324.107 62.7596 323.907 62.5274C323.708 62.2952 323.568 62.0181 323.5 61.7199L323.02 60.1199L385.02 24.3699C385.32 24.183 385.666 24.0826 386.02 24.0799C386.216 24.0773 386.409 24.1256 386.58 24.2199V24.2199L390.47 26.5099Z"
        fill="white"
      />
      <motion.path
        opacity="0.1"
        d="M290 162.43C290.059 162.516 290.13 162.593 290.21 162.66L286.28 160.4C286.125 160.292 285.998 160.148 285.91 159.98C285.866 159.901 285.833 159.817 285.81 159.73C285.734 159.505 285.697 159.268 285.7 159.03V70.18C285.695 70.0935 285.695 70.0066 285.7 69.92C285.72 69.7386 285.75 69.5583 285.79 69.38C285.79 69.29 285.84 69.2 285.86 69.11C285.951 68.8359 286.061 68.5686 286.19 68.31C286.231 68.2203 286.277 68.1334 286.33 68.05L290.39 70.4C290.001 71.0445 289.784 71.7778 289.76 72.53V161.34C289.75 161.466 289.75 161.594 289.76 161.72C289.755 161.79 289.755 161.86 289.76 161.93V162.04L289.82 162.18C289.865 162.273 289.926 162.358 290 162.43V162.43Z"
        fill="black"
      />
      <motion.path
        d="M224.68 307.74C224.629 306.811 224.359 305.906 223.894 305.1C223.428 304.294 222.779 303.609 222 303.1L219.82 301.84C218.988 301.425 218.07 301.208 217.14 301.208C216.21 301.208 215.292 301.425 214.46 301.84L142.05 343.62C141.27 344.129 140.622 344.814 140.156 345.62C139.69 346.426 139.421 347.331 139.37 348.26V356.79C139.421 357.72 139.69 358.624 140.156 359.43C140.622 360.236 141.27 360.921 142.05 361.43L144.24 362.69C145.069 363.109 145.986 363.328 146.915 363.328C147.844 363.328 148.76 363.109 149.59 362.69L222 320.91C222.781 320.402 223.43 319.718 223.896 318.911C224.362 318.105 224.63 317.2 224.68 316.27V307.74Z"
        fill="#37474F"
      />
      <motion.path
        d="M139.37 356.79C139.419 357.72 139.688 358.625 140.154 359.431C140.62 360.238 141.269 360.923 142.05 361.43L144.24 362.69C144.996 363.068 145.823 363.282 146.667 363.319C147.512 363.355 148.354 363.212 149.14 362.9C147.87 363.38 146.91 362.67 146.91 361.14V352.62C146.941 351.684 147.213 350.771 147.7 349.97L140.16 345.62C139.674 346.418 139.402 347.327 139.37 348.26V356.79Z"
        fill="#263238"
      />
      <motion.path
        d="M224.59 307C224.28 305.86 223.24 305.5 222 306.21L149.59 348C148.795 348.489 148.143 349.179 147.7 350L140.17 345.65C140.612 344.831 141.26 344.142 142.05 343.65L214.45 301.86C215.282 301.445 216.2 301.228 217.13 301.228C218.06 301.228 218.978 301.445 219.81 301.86L222 303.13C222.672 303.566 223.248 304.133 223.693 304.799C224.138 305.464 224.443 306.213 224.59 307Z"
        fill="#455A64"
      />
      <motion.path
        d="M156.13 349C157.54 348.19 158.68 348.85 158.68 350.47C158.632 351.354 158.376 352.214 157.933 352.981C157.49 353.747 156.872 354.398 156.13 354.88C154.73 355.69 153.59 355.03 153.59 353.41C153.638 352.527 153.893 351.668 154.334 350.902C154.775 350.136 155.39 349.485 156.13 349V349Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M164.55 344.17C165.95 343.36 167.09 344.02 167.09 345.64C167.042 346.523 166.788 347.382 166.346 348.148C165.905 348.915 165.29 349.566 164.55 350.05C163.14 350.86 162.01 350.2 162.01 348.58C162.057 347.697 162.312 346.838 162.753 346.072C163.194 345.305 163.81 344.654 164.55 344.17V344.17Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M173 339.32C174.41 338.51 175.55 339.16 175.55 340.79C175.502 341.672 175.245 342.531 174.802 343.296C174.359 344.06 173.742 344.709 173 345.19C171.6 346 170.46 345.34 170.46 343.72C170.508 342.839 170.763 341.981 171.204 341.217C171.646 340.452 172.261 339.802 173 339.32Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M181.38 334.46C182.78 333.65 183.92 334.31 183.92 335.93C183.872 336.811 183.617 337.669 183.175 338.433C182.734 339.198 182.119 339.848 181.38 340.33C179.97 341.14 178.83 340.49 178.83 338.86C178.878 337.978 179.134 337.119 179.578 336.354C180.021 335.59 180.638 334.941 181.38 334.46V334.46Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M189.79 329.6C191.2 328.79 192.34 329.45 192.34 331.07C192.291 331.954 192.034 332.813 191.591 333.579C191.148 334.346 190.531 334.997 189.79 335.48C188.39 336.29 187.25 335.63 187.25 334.01C187.297 333.127 187.552 332.268 187.993 331.502C188.435 330.736 189.05 330.084 189.79 329.6V329.6Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M198.21 324.74C199.61 323.93 200.75 324.59 200.75 326.21C200.702 327.093 200.447 327.952 200.006 328.718C199.564 329.484 198.949 330.135 198.21 330.62C196.8 331.43 195.66 330.77 195.66 329.15C195.708 328.266 195.963 327.406 196.407 326.64C196.85 325.873 197.468 325.222 198.21 324.74V324.74Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M206.62 319.88C208.02 319.07 209.16 319.73 209.16 321.35C209.112 322.233 208.858 323.092 208.417 323.858C207.975 324.624 207.36 325.276 206.62 325.76C205.22 326.57 204.08 325.91 204.08 324.29C204.13 323.408 204.385 322.549 204.826 321.783C205.267 321.018 205.882 320.366 206.62 319.88V319.88Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M215 315C216.41 314.19 217.55 314.84 217.55 316.47C217.502 317.353 217.245 318.211 216.802 318.976C216.359 319.74 215.742 320.389 215 320.87C213.6 321.68 212.46 321.02 212.46 319.4C212.508 318.519 212.763 317.661 213.204 316.897C213.646 316.132 214.261 315.483 215 315V315Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M224.68 335.87C224.629 334.94 224.359 334.036 223.894 333.23C223.428 332.424 222.779 331.739 222 331.23L219.82 330C218.988 329.584 218.07 329.368 217.14 329.368C216.21 329.368 215.292 329.584 214.46 330L142.05 371.78C141.269 372.288 140.62 372.972 140.154 373.779C139.688 374.585 139.419 375.49 139.37 376.42V384.95C139.421 385.879 139.69 386.784 140.156 387.59C140.622 388.396 141.27 389.081 142.05 389.59L144.24 390.85C145.069 391.269 145.986 391.488 146.915 391.488C147.844 391.488 148.76 391.269 149.59 390.85L222 349C222.781 348.492 223.43 347.808 223.896 347.001C224.362 346.195 224.63 345.29 224.68 344.36V335.87Z"
        fill="#37474F"
      />
      <motion.path
        d="M139.37 384.92C139.421 385.849 139.69 386.754 140.156 387.56C140.622 388.366 141.27 389.051 142.05 389.56L144.24 390.82C144.996 391.198 145.823 391.412 146.667 391.449C147.512 391.485 148.354 391.342 149.14 391.03C147.87 391.51 146.91 390.8 146.91 389.27V380.74C146.942 379.807 147.214 378.898 147.7 378.1L140.16 373.75C139.674 374.548 139.402 375.457 139.37 376.39V384.92Z"
        fill="#263238"
      />
      <motion.path
        d="M224.59 335.11C224.28 333.97 223.24 333.61 222 334.32L149.59 376.11C148.795 376.599 148.143 377.289 147.7 378.11L140.17 373.76C140.612 372.941 141.26 372.252 142.05 371.76L214.45 330C215.282 329.584 216.2 329.368 217.13 329.368C218.06 329.368 218.978 329.584 219.81 330L222 331.27C222.668 331.702 223.242 332.265 223.688 332.925C224.133 333.585 224.44 334.328 224.59 335.11V335.11Z"
        fill="#455A64"
      />
      <motion.path
        d="M156.13 377.16C157.54 376.35 158.68 377.01 158.68 378.63C158.631 379.514 158.374 380.373 157.931 381.139C157.488 381.905 156.871 382.557 156.13 383.04C154.73 383.85 153.59 383.19 153.59 381.57C153.637 380.687 153.892 379.828 154.333 379.062C154.774 378.295 155.39 377.644 156.13 377.16V377.16Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M164.55 372.3C165.95 371.49 167.09 372.15 167.09 373.77C167.042 374.653 166.787 375.512 166.345 376.278C165.904 377.044 165.289 377.696 164.55 378.18C163.14 378.99 162.01 378.33 162.01 376.71C162.056 375.827 162.31 374.967 162.751 374.201C163.192 373.434 163.809 372.783 164.55 372.3V372.3Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M173 367.45C174.41 366.63 175.55 367.29 175.55 368.91C175.503 369.794 175.248 370.655 174.804 371.421C174.361 372.188 173.743 372.838 173 373.32C171.6 374.13 170.46 373.47 170.46 371.85C170.508 370.969 170.763 370.111 171.204 369.347C171.646 368.582 172.261 367.933 173 367.45V367.45Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M181.38 362.59C182.78 361.78 183.92 362.43 183.92 364.06C183.872 364.941 183.617 365.799 183.175 366.563C182.734 367.328 182.119 367.978 181.38 368.46C179.97 369.27 178.83 368.62 178.83 366.99C178.878 366.108 179.134 365.249 179.578 364.484C180.021 363.72 180.638 363.071 181.38 362.59V362.59Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M189.79 357.73C191.2 356.92 192.34 357.58 192.34 359.2C192.292 360.082 192.035 360.941 191.592 361.706C191.149 362.47 190.532 363.119 189.79 363.6C188.39 364.41 187.25 363.76 187.25 362.14C187.297 361.257 187.552 360.398 187.993 359.632C188.435 358.865 189.05 358.214 189.79 357.73V357.73Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M198.21 352.87C199.61 352.06 200.75 352.72 200.75 354.34C200.7 355.223 200.445 356.081 200.004 356.847C199.562 357.613 198.948 358.264 198.21 358.75C196.8 359.56 195.66 358.9 195.66 357.28C195.707 356.396 195.962 355.536 196.405 354.769C196.849 354.003 197.467 353.352 198.21 352.87V352.87Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M206.62 348C208.02 347.19 209.16 347.85 209.16 349.47C209.112 350.353 208.857 351.212 208.416 351.978C207.974 352.744 207.359 353.395 206.62 353.88C205.22 354.69 204.08 354.03 204.08 352.41C204.128 351.527 204.383 350.668 204.824 349.902C205.265 349.136 205.88 348.485 206.62 348V348Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M215 343.16C216.41 342.34 217.55 343 217.55 344.62C217.498 345.499 217.241 346.354 216.798 347.114C216.355 347.875 215.739 348.521 215 349C213.6 349.81 212.46 349.15 212.46 347.53C212.513 346.654 212.77 345.802 213.211 345.043C213.652 344.285 214.265 343.64 215 343.16V343.16Z"
        fill="#E6E6E6"
      />
      <motion.path
        d="M137 358.32C136.948 357.391 136.678 356.487 136.213 355.681C135.747 354.875 135.099 354.189 134.32 353.68L132.13 352.42C131.301 352.001 130.384 351.782 129.455 351.782C128.526 351.782 127.609 352.001 126.78 352.42L93.3099 371.78C92.5292 372.288 91.8795 372.973 91.4138 373.779C90.9481 374.585 90.6795 375.49 90.6299 376.42V413.11C90.6818 414.039 90.9514 414.943 91.417 415.749C91.8825 416.555 92.5309 417.241 93.3099 417.75L95.4899 419C96.3222 419.416 97.2397 419.632 98.1699 419.632C99.1001 419.632 100.018 419.416 100.85 419L134.31 399.64C135.092 399.134 135.744 398.449 136.212 397.643C136.679 396.836 136.949 395.931 137 395V358.32Z"
        className="rainbow"
      />
      <motion.path
        opacity="0.2"
        d="M90.6299 413.11C90.6818 414.039 90.9514 414.943 91.417 415.749C91.8825 416.555 92.5309 417.241 93.3099 417.75L95.4899 419C96.2458 419.378 97.073 419.592 97.9174 419.629C98.7619 419.665 99.6044 419.522 100.39 419.21C99.1299 419.69 98.1699 418.98 98.1699 417.45V380.77C98.2032 379.838 98.4715 378.93 98.9499 378.13L91.4099 373.78C90.9303 374.58 90.6619 375.488 90.6299 376.42V413.11Z"
        fill="black"
      />
      <motion.path
        opacity="0.3"
        d="M136.9 357.56C136.58 356.43 135.55 356.06 134.31 356.78L100.85 376.13C100.055 376.618 99.4025 377.308 98.9599 378.13L91.4199 373.78C91.8685 372.963 92.5192 372.274 93.3099 371.78L126.76 352.43C127.592 352.015 128.51 351.798 129.44 351.798C130.37 351.798 131.288 352.015 132.12 352.43L134.31 353.69C134.982 354.126 135.558 354.693 136.003 355.359C136.448 356.024 136.753 356.773 136.9 357.56Z"
        fill="white"
      />
      <motion.path
        d="M117.08 372.67C120.3 370.81 122.91 372.31 122.91 376.03C122.8 378.053 122.215 380.022 121.203 381.777C120.19 383.532 118.777 385.023 117.08 386.13C113.86 387.99 111.25 386.48 111.25 382.76C111.358 380.738 111.942 378.77 112.955 377.016C113.969 375.263 115.382 373.774 117.08 372.67V372.67Z"
        fill="#FAFAFA"
      />
      <motion.path
        d="M128.57 392.49C128.57 386.38 124.28 383.91 118.99 386.96L115.68 388.87C110.4 391.92 106.11 399.35 106.11 405.46V407.1C109.11 408.1 125.47 398.62 128.57 393.91V392.49Z"
        fill="#FAFAFA"
      />
      <motion.path
        d="M438.2 286.26L426.01 279.26C425.53 279 424.82 279.18 424.01 279.83C423.56 280.17 423.11 280.5 422.65 280.83L422.15 281.15L421.25 281.72L420.6 282.08C420.34 282.23 420.07 282.39 419.8 282.53C419.53 282.67 419.31 282.77 419.06 282.89C418.81 283.01 418.58 283.14 418.33 283.25L417.51 283.6L416.83 283.88C416.53 284 416.23 284.11 415.93 284.21L415.31 284.44L414.31 284.73L413.74 284.9C413.4 285 413.05 285.08 412.74 285.16L412.23 285.28L411.13 285.5L410.68 285.58L409.51 285.75H409.13C408.72 285.75 408.31 285.84 407.9 285.86H407.58C407.15 285.86 406.73 285.86 406.3 285.91H406C405.56 285.91 405.12 285.91 404.68 285.91H404.48C404.02 285.91 403.57 285.85 403.11 285.81H402.98L401.57 285.63H401.5L400.32 285.4C400.073 285.128 399.787 284.896 399.47 284.71V284.71L399.33 284.63L387.33 277.69C386.33 277.12 385.41 277.53 384.83 278.99C376.96 298.51 360.21 318.31 349.05 323.26C348.828 323.352 348.614 323.462 348.41 323.59C348.27 323.67 348.15 323.76 348.01 323.85C347.87 323.94 347.9 323.93 347.84 323.98C347.671 324.102 347.511 324.236 347.36 324.38V324.38C347.2 324.54 347.04 324.7 346.89 324.88C346.091 325.746 345.648 326.882 345.65 328.06L347.26 371.92C347.26 384.7 352.7 398.2 363.8 404.65L375.98 411.65L376.14 411.74C381.775 414.865 388.253 416.132 394.65 415.36C397.042 414.969 399.259 413.864 401.01 412.19C425.36 387 437.23 352.85 437.23 334L438.84 288.14C438.87 287.12 438.63 286.51 438.2 286.26Z"
        className="rainbow"
      />
      <motion.path
        opacity="0.15"
        d="M375.63 411.49L363.74 404.62C352.65 398.17 347.2 384.67 347.2 371.89L345.58 328C345.592 327.237 345.779 326.487 346.126 325.808C346.472 325.129 346.97 324.537 347.58 324.08C347.314 324.306 347.066 324.554 346.84 324.82L359.02 331.82C358.215 332.69 357.771 333.834 357.78 335.02L359.38 378.87C359.37 391.6 364.73 405 375.63 411.49Z"
        fill="black"
      />
      <motion.path
        opacity="0.3"
        d="M438.18 286.24C437.71 285.99 437.01 286.18 436.18 286.83C425.18 295.21 408.83 295.22 400.49 285.51L400.34 285.34C404.452 286.138 408.687 286.056 412.765 285.099C416.843 284.143 420.672 282.333 424 279.79C424.86 279.13 425.56 278.96 426 279.22L438.18 286.24Z"
        fill="white"
      />
      <motion.path
        opacity="0.5"
        d="M399.33 284.57C398.4 284.13 397.55 284.57 397 285.95C389.14 305.48 372.38 325.27 361.21 330.22C360.37 330.602 359.623 331.161 359.02 331.86L346.84 324.86C347.066 324.594 347.314 324.346 347.58 324.12L347.85 323.9C347.947 323.839 348.041 323.772 348.13 323.7L348.43 323.52C348.622 323.401 348.822 323.297 349.03 323.21C360.19 318.26 376.95 298.46 384.81 278.93C385.23 277.93 385.81 277.4 386.48 277.4C386.774 277.402 387.061 277.485 387.31 277.64L399.33 284.57Z"
        fill="white"
      />
      <motion.path
        d="M459.11 328.36L452.61 324.6L427.12 384.67L431.47 387.18L459.11 328.36Z"
        fill="#455A64"
      />
      <motion.path
        d="M420.5 368.69L413.99 364.94L407 379.73L413.51 383.48L420.5 368.69Z"
        fill="#455A64"
      />
      <motion.path
        d="M433.14 404.79L413.51 383.48L420.5 368.69L431.47 387.18L459.11 328.36L469.36 338.36L433.14 404.79Z"
        fill="#37474F"
      />
      <motion.path
        d="M407 379.73L413.51 383.48L433.14 404.79L426.64 401.08L407 379.73Z"
        fill="#263238"
      />
      <rect x="259" y="244" width="7" height="24" fill="#455A64" />
    </DemoImage>
  );
};

const DemoImage = styled.svg`
  width: 100%;
  max-width: 100%;
  height: 220px;
`;
export default Demo;
