import styled from "styled-components";
import emoji from "../../img/emoji.png";
import BooksBlock from "./BooksBlock";

import AnimoDemo from "../../components/AnimoDemo";

const Home = () => {
  return (
    <div className="home">
      <RoundedBlock>
        <HomeFlex>
          <ImageFlex>
            <img alt="Jim's Emoji" src={emoji}></img>
          </ImageFlex>
          <HomeText>
            <h1 style={{ margin: 0 }}>Hi, I'm Jim! 👋🏾</h1>
            <p>
              I'm a software engineer living in Nigeria. <br />
              Passionate about creating software that makes the world a more
              equal and just place.{" "}
            </p>
          </HomeText>
        </HomeFlex>
      </RoundedBlock>
      <HomeGrid>
        <GridColumn>
          <RoundedCard>
            <p>
              Currently contracted part-time as a Software Engineer for
              Wissenschaft Integrated Services in Abuja.
            </p>
            <CoolButton href="https://wissenschaft.com.ng" target="_blank">
              Visit Wissenschaft
            </CoolButton>
          </RoundedCard>
          <RoundedCard>
            <p>
              I've recently finished the server-side development for the
              National Library of Nigeria's new online virtual library.
            </p>
            <CoolButton href="https://virtuall.nln.gov.ng" target="_blank">
              <div style={{ display: "inline" }}>Visit the virtual library</div>
            </CoolButton>
          </RoundedCard>
          <RoundedCard>
            <p>
              At Animo, I worked on the user interface for IDCrypt: the first
              fully-functional decentralized identity wallet of its kind.
            </p>
            <CoolButton href="https://www.idcrypt.global" target="_blank">
              <div style={{ display: "inline" }}>Install the app</div>
            </CoolButton>
          </RoundedCard>
          <RoundedCardProject>
            <AnimoDemo />
            <p>
              I also contributed to an open-source interactive demo that
              explains the concepts of self-sovereign identity.
            </p>
            <CoolButton href="https://demo.animo.id" target="_blank">
              <div style={{ display: "inline" }}>Try demo</div>
            </CoolButton>
          </RoundedCardProject>
        </GridColumn>
        <GridColumn>
          <BooksBlock />
          <RoundedCard>
            <p>
              I wrote a JavaScript package that implements a pluggable extension
              that adds Bluetooth Low Energy (BLE) data transport capabilities
              to the Hyperledger Aries Framework JavaScript, a popular
              self-sovereign identity framework.
            </p>
            <CoolButton
              href="https://www.npmjs.com/package/@aries-framework/transport-ble"
              target="_blank"
            >
              <div style={{ display: "inline" }}>Check it out on NPM</div>
            </CoolButton>
          </RoundedCard>
          <RoundedCard>
            <p>
              I also contributed to the SDK that implements the native Bluetooth
              handlers I use in the BLE transport plugin.
            </p>
            <CoolButton
              href="https://www.npmjs.com/package/@animo-id/react-native-ble-didcomm"
              target="_blank"
            >
              <div style={{ display: "inline" }}>Check it out on NPM</div>
            </CoolButton>
          </RoundedCard>
          <RoundedCardBlue>
            <p>Where you can reach me 📇</p>
            <CoolButton
              href="mailto:contact@jimezesinachi.com"
              rel="noreferrer"
            >
              Send me an email
            </CoolButton>
            <CoolButton
              href="https://github.com/jimezesinachi"
              target="_blank"
              rel="noreferrer"
            >
              Find me on GitHub
            </CoolButton>
            <CoolButton
              href="https://www.linkedin.com/in/jimezesinachi"
              target="_blank"
              rel="noreferrer"
            >
              Connect with me on LinkedIn
            </CoolButton>
            <CoolButton
              href="https://twitter.com/jimezesinachi"
              target="_blank"
              rel="noreferrer"
            >
              Follow me on Twitter
            </CoolButton>
          </RoundedCardBlue>
        </GridColumn>
      </HomeGrid>
    </div>
  );
};

const CoolButton = styled.a`
  color: black;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 42px;
  margin-top: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  border-radius: 12px;
  background: #ededed;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
`;

const HomeGrid = styled.div`
  display: flex;
  margin-left: -22px;
  width: auto;
  @media (max-width: 400px) {
    display: block;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const GridColumn = styled.div`
  padding-left: 22px;
  background-clip: padding-box;
`;

const RoundedCard = styled.div`
  text-decoration: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0em;
  padding: 20px;
  border-radius: 12px;
  border: none;
  background: var(--bg0);
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 12px 30px -10px;
  color: var(--c1);
  position: relative;
  width: 400px;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const RoundedCardBlue = styled.div`
  text-decoration: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0em;
  padding: 20px;
  border-radius: 12px;
  border: none;
  background: #94c1f2;
  box-shadow: rgb(0 0 0 / 8%) 0px 12px 30px -10px;
  color: var(--c1);
  position: relative;
  width: 400px;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const RoundedCardProject = styled.div`
  text-decoration: none;
  box-sizing: border-box;
  width: auto;
  min-width: 0em;
  padding: 20px;
  border-radius: 12px;
  border: none;
  color: white;
  background: #363636;
  box-shadow: rgb(0 0 0 / 8%) 0px 12px 30px -10px;
  color: white;
  position: relative;
  width: 400px;
  overflow: hidden;
  margin-bottom: 1rem;
`;

const RoundedBlock = styled.div`
  background: white;
  box-sizing: border-box;
  width: 800px;
  min-width: 0em;
  margin: 0;
  width: 100%;
  margin-bottom: 22px;
  padding: 1.5rem;
  border-radius: 12px;
  border: none;
  box-shadow: rgb(0 0 0 / 8%) 0px 12px 30px -10px;
  color: var(--c1);
  position: relative;
  img {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  @media (max-width: 400px) {
    width: 400px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;

const ImageFlex = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  overflow: hidden;
  flex: 0 0 auto;
  margin: 0;
  margin-right: 1rem;
`;

const HomeText = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  margin: 0;
`;

const HomeFlex = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default Home;
